import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import earlogo from '../../../assets/img/earlogo.svg';
import india from '../../../assets/img/india.svg';
import iconplay from '../../../assets/img/iconplay.svg';
import Footer from '../../components/Footer/Footer';
import dashedplay from '../../../assets/img/dashedplay.svg';
import usa from '../../../assets/img/usa.svg';
import uk from '../../../assets/img/uk.svg';
import '../../scss/Quiz.scss';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PlusIcon from '../../../assets/img/plus.png';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Mid_green_check from '../../../assets/img/mid-tick-green.svg';
import Red_tick from '../../../assets/img/red-cross-tick.svg';

import Circletick from '../../../assets/img/charm_cicle_tick.png';
import Circleinfo from '../../../assets/img/infoicon.svg';
import Circleincorrect from '../../../assets/img/incorrect.png';

import Thinking from '../../../assets/img/thinking.png';
import Sad from '../../../assets/img/sad.png';

import Star from '../../../assets/img/star.png';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Container,
  FormControl,
  Grid,
  Input,
  Box,
  Paper,
  Typography,
  SvgIcon,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { fetchWordData } from '../../Utils/firebase-services';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import RemoteErrorLog from '../../Utils/api';

const QuizResult = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const savedState = useRef(
    state || JSON.parse(localStorage.getItem('quizState') || '{}')
  );
  const { navigated }: any = savedState.current || {};
  const [questionAnswerData, setQuestionAnswerData] = useState<{
    [key: string]: string;
  }>();

  const [currentWordDetail, setCurrentWordDetail] = useState<any>();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [description, setDescription] = useState<boolean>(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const questions = useSelector((state: any) => state.questions);
  const questionDetailData = useSelector((state: any) => state.questiondetail);
  const results = useSelector((state: any) => state.questionresult);

  const [documents, setDocuments] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

  useEffect(() => {
    setIsLoading(true);
    if (navigated) {
      if (questionDetailData?.detail && questions?.[currentQuestionIndex]) {
        const value =
          questionDetailData?.detail?.[questions?.[currentQuestionIndex]];
        setCurrentWordDetail(value);
        setIsLoading(false);
      } else if (documents && documents[questions?.[currentQuestionIndex]]) {
        const value = documents[questions?.[currentQuestionIndex]];
        setCurrentWordDetail(value);
        setIsLoading(false);
      } else {
        fetchWordData(questions[currentQuestionIndex])
          .then((wordField: any) => {
            if (wordField) {
              const currentWordData = {
                [questions[currentQuestionIndex]]: wordField,
              };

              setCurrentWordDetail(wordField);
              setDocuments((prevState: any) => ({
                ...prevState, // Spread the previous state to maintain its values
                ...currentWordData, // Spread the new value to add it to the state
              }));
            }
          })
          .finally(() => setIsLoading(false));
      }
    } else {
      navigate('/home');
    }
    if (questions.length == 0) {
      navigate('/home');
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const qnaData = questions.reduce(
      (accumulator: any, currentValue: string) => {
        accumulator[currentValue] = '';
        return accumulator;
      },
      {}
    );
    setQuestionAnswerData(qnaData);
  }, [questions]);

  useEffect(() => {
    getCurrentQuestionData();
  }, [currentQuestionIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 59) {
        setSeconds(seconds + 1);
      } else {
        setSeconds(0);
        setMinutes(minutes + 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes]);

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const getCurrentQuestionData = async () => {
    const currentWordName = questions[currentQuestionIndex];
    const wordDetails = documents?.[currentWordName];
    if (wordDetails) {
      setCurrentWordDetail(wordDetails);
    }
  };

  const base64ToBlob = (base64Data: string, contentType = 'audio/wav') => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  // Function to play sound from base64 data
  const playSound = async (base64Sound: string) => {
    try {
      const audioBlob = base64ToBlob(base64Sound);
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
      };

      audio.onerror = (error) => {
        URL.revokeObjectURL(audioUrl);
      };

      await audio.play();
    } catch (error) {
      RemoteErrorLog(error, 'QuizResult.tsx');
    }
  };

  const playDynamicSound = useCallback(
    async (countryCode: number, playbackSpeed: any) => {
      const speedValue = Number(playbackSpeed);

      try {
        let base64Audio;
        switch (countryCode) {
          case 1:
            base64Audio = currentWordDetail?.audio_IN;
            break;
          case 2:
            base64Audio = currentWordDetail?.audio_US;
            break;
          case 3:
            base64Audio = currentWordDetail?.audio_UK;
            break;
          default:
            throw new Error('Invalid country code');
        }

        if (!base64Audio) {
          throw new Error('No audio data available');
        }

        const audioBlob = base64ToBlob(base64Audio);
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.playbackRate = speedValue;

        audio.onended = () => {
          URL.revokeObjectURL(audioUrl);
        };

        audio.onerror = (error) => {
          URL.revokeObjectURL(audioUrl);
        };

        await audio.play();
      } catch (error) {
        RemoteErrorLog(error, 'QuizResult.tsx');
      }
    },
    [currentWordDetail]
  );
  const hideModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  return (
    <Box>
      <Navbar />
      <Header />

      <main className="page-bg overflow-hidden">
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          {/* <Box className="googleAds top mx-auto">
            <img src={googleads980} alt="" />
          </Box> */}
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'center !important' }}
          >
            {/* <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }} className='order-item-2'>
              <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box>
            </Grid> */}
            <Grid item lg={6} xs={12}>
              {isLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                  }}
                >
                  {' '}
                  <CircularProgress
                    size={56}
                    sx={{
                      color: 'primary.main',
                    }}
                  />{' '}
                </Box>
              ) : (
                <section className="quiz-step">
                  <Typography variant="h3">Let’s Spell!</Typography>
                  <Paper className="box-container quiz-container">
                    <Typography
                      sx={{
                        textAlign: 'right',
                        marginRight: '12px',
                        marginTop: '5px',
                      }}
                    >
                      {currentQuestionIndex + 1}/{questions.length}
                    </Typography>

                    <Box className="round-quiz result-quiz">
                      <ul>
                        {Object.entries(results.result).map(
                          ([key, value], index) => {
                            const isCorrect = key === value;
                            return (
                              <li
                                className={`${isCorrect ? '' : 'red-box'} `}
                              ></li>
                            );
                          }
                        )}
                      </ul>
                    </Box>
                    <Box className="d-flex justify-content-between align-items-center mt-3">
                      <Box>
                        <Typography
                          variant="body1"
                          className="font-400 text-blackish"
                        >
                          <img
                            width={30}
                            className="me-2 align-middle"
                            src={earlogo}
                            alt=" "
                          />
                          Click a button below to listen to the Indian, USA or
                          UK accent.
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="mt-4 accent-grid-type">
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            India
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={india} alt="" className="flag" />
                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playSound(currentWordDetail?.audio_IN);
                            }}
                          >
                            <img
                              src={iconplay}
                              alt="image"
                              className="btn-speaker"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            USA
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={usa} alt="" className="flag" />
                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playSound(currentWordDetail?.audio_US);
                            }}
                          >
                            <img
                              src={iconplay}
                              alt="image"
                              className="btn-speaker"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            UK
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={uk} alt="" className="flag" />

                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playSound(currentWordDetail?.audio_UK);
                            }}
                          >
                            <img
                              src={iconplay}
                              alt="image"
                              className="btn-speaker"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            India
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={india} alt="" className="flag" />
                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playDynamicSound(1, 0.55);
                            }}
                          >
                            <img
                              src={dashedplay}
                              alt="Play"
                              className="btn-slow"
                            />
                          </Button>
                        </Box>
                        <Typography
                          variant="body2"
                          className="text-right text-light font-600 slow-speed"
                        >
                          Slow Speed
                        </Typography>
                      </Box>
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            USA
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={usa} alt="" className="flag" />
                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playDynamicSound(2, 0.55);
                            }}
                          >
                            <img
                              src={dashedplay}
                              alt="image"
                              className="btn-slow"
                            />
                          </Button>
                        </Box>
                        <Typography
                          variant="body2"
                          className="text-right text-light font-600 slow-speed"
                        >
                          Slow Speed
                        </Typography>
                      </Box>
                      <Box className="accent-box">
                        <Box>
                          <Typography
                            variant="body1"
                            className="font-600 text-light"
                          >
                            UK
                          </Typography>
                        </Box>
                        <Box className="play-inside-img">
                          <img src={uk} alt="" className="flag" />
                          <Button
                            style={{ padding: 0, minWidth: 'auto' }}
                            onClick={() => {
                              playDynamicSound(3, 0.55);
                            }}
                          >
                            <img
                              src={dashedplay}
                              alt="image"
                              className="btn-slow"
                            />
                          </Button>
                        </Box>
                        <Typography
                          variant="body2"
                          className="text-right text-light font-600 slow-speed"
                        >
                          Slow Speed
                        </Typography>
                      </Box>
                    </Box>

                    <Accordion
                      expanded={description}
                      onClick={() => setDescription(!description)}
                      className="part-speech-card"
                    >
                      <AccordionSummary
                        expandIcon={
                          description ? (
                            <SvgIcon className="part-speech-svg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <circle
                                  cx="15"
                                  cy="15"
                                  r="15"
                                  fill="#5FCD6A"
                                  fill-opacity="0.5"
                                />
                                <path
                                  d="M9.16797 15H20.8346"
                                  stroke="#2A9235"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </SvgIcon>
                          ) : (
                            <img src={PlusIcon} />
                          )
                        }
                      >
                        <Typography
                          variant="h4"
                          className="mb-0 text-para-black font-22 font-500"
                        >
                          Part of Speech:
                          <span className="font-400 ps-2">
                            <i> {currentWordDetail?.part_of_speech}</i>
                          </span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="h4" className="font-22 font-500">
                          Description:
                        </Typography>
                        <Typography variant="body1" className="text-para-black">
                          {currentWordDetail?.def}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    {questions[currentQuestionIndex] ===
                      results.result[questions[currentQuestionIndex]] && (
                      <Box>
                        <Box className="accomplish-section">
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={Mid_green_check}
                              className="ps-2"
                              alt=""
                            />
                            <Typography variant="h4" sx={{ fontSize: '16px' }}>
                              {results.result[questions[currentQuestionIndex]]}
                            </Typography>
                          </Box>
                          <Typography
                            className="font-600"
                            variant="h4"
                            sx={{ fontSize: '16px' }}
                          >
                            <span style={{ color: '#2A9235' }}>(Correct)</span>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            background: '#5FCD6A1A',
                            marginTop: '20px',
                            padding: '7px 12px 7px 16px',
                            display: 'flex',
                            gap: '11px',
                          }}
                        >
                          <img src={Circletick} alt="green-tick" />
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: '14px',
                              fontWeight: '500',
                              fontFamily: 'poppins',
                            }}
                          >
                            Well done! You got this right.
                          </Typography>
                          <img src={Star} alt="green-tick" />
                        </Box>
                      </Box>
                    )}
                    {questions[currentQuestionIndex] !==
                      results.result[questions[currentQuestionIndex]] &&
                      results.result[questions[currentQuestionIndex]] !==
                        '' && (
                        <Box>
                          <Box className="accomplish-section border-red-incorrect">
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                              }}
                            >
                              <img src={Red_tick} className="ps-2" alt="" />
                              <Typography
                                variant="h4"
                                sx={{ fontSize: '16px' }}
                              >
                                {
                                  results.result[
                                    questions[currentQuestionIndex]
                                  ]
                                }
                              </Typography>
                            </Box>
                            <Typography
                              className="font-600"
                              variant="h4"
                              sx={{ fontSize: '16px' }}
                            >
                              <span style={{ color: '#CF232A' }}>
                                (Incorrect)
                              </span>
                            </Typography>
                          </Box>
                          <Box className="accomplish-section">
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                              }}
                            >
                              <img
                                src={Mid_green_check}
                                className="ps-2"
                                alt=""
                              />
                              <Typography
                                variant="h4"
                                sx={{ fontSize: '16px' }}
                              >
                                {questions[currentQuestionIndex]}
                              </Typography>
                            </Box>
                            <Typography
                              className="font-600"
                              variant="h4"
                              sx={{ fontSize: '16px' }}
                            ></Typography>
                          </Box>
                          <Box
                            sx={{
                              background: '#EC1C241A',
                              marginTop: '20px',
                              padding: '7px 12px 7px 16px',
                              display: 'flex',
                              gap: '11px',
                            }}
                          >
                            <img
                              src={Circleincorrect}
                              alt="green-tick"
                              style={{ height: 18, width: 18 }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                fontFamily: 'poppins',
                              }}
                            >
                              Sorry, you didn't get this right.
                            </Typography>
                            <img
                              src={Sad}
                              alt="green-tick"
                              style={{ width: 15, height: 15 }}
                            />
                          </Box>
                        </Box>
                      )}
                    {results.result[questions[currentQuestionIndex]] === '' && (
                      <Box>
                        <Box className="accomplish-section border-red-incorrect">
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <img src={Red_tick} className="ps-2" alt="" />
                            <Typography
                              variant="h4"
                              sx={{ fontSize: '16px' }}
                            ></Typography>
                          </Box>
                          <Typography
                            className="font-600"
                            variant="h4"
                            sx={{ fontSize: '16px' }}
                          >
                            <span style={{ color: '#CF232A' }}>
                              (Not attempted)
                            </span>
                          </Typography>
                        </Box>
                        <Box className="accomplish-section">
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={Mid_green_check}
                              className="ps-2"
                              alt=""
                            />
                            <Typography variant="h4" sx={{ fontSize: '16px' }}>
                              {questions[currentQuestionIndex]}
                            </Typography>
                          </Box>
                          <Typography
                            className="font-600"
                            variant="h4"
                            sx={{ fontSize: '16px' }}
                          ></Typography>
                        </Box>
                        <Box
                          sx={{
                            background: '#EC1C241A',
                            marginTop: '20px',
                            padding: '7px 12px 7px 16px',
                            display: 'flex',
                            gap: '11px',
                          }}
                        >
                          <img
                            src={Circleinfo}
                            alt="green-tick"
                            style={{ height: 15, width: 15 }}
                          />
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: '14px',
                              fontWeight: '500',
                              fontFamily: 'poppins',
                            }}
                          >
                            You didn't answer this question.
                          </Typography>
                          <img
                            src={Thinking}
                            alt="green-tick"
                            style={{ width: 15, height: 15 }}
                          />
                        </Box>
                      </Box>
                    )}

                    <Box
                      className="d-flex  gap-3 pt-4 flex-wrap btn-combo "
                      sx={{ paddingBottom: '15px', paddingTop: '15px' }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={
                          <ArrowBackIosIcon className="custom-icon-class" />
                        }
                        className="btn-quiz-next btn-previous-quiz"
                        onClick={goToPreviousQuestion}
                      >
                        Previous
                      </Button>
                      {currentQuestionIndex + 1 !== questions.length && (
                        <Button
                          variant="contained"
                          className="btn btn-primary btn-quiz-next "
                          endIcon={<NavigateNextIcon />}
                          onClick={goToNextQuestion}
                        >
                          Next
                        </Button>
                      )}
                      {currentQuestionIndex + 1 === questions.length && (
                        <Button
                          variant="contained"
                          className="btn btn-primary btn-quiz-next "
                          onClick={() =>
                            navigate('/result', {
                              state: { navigated: true },
                            })
                          }
                        >
                          Done
                        </Button>
                      )}
                    </Box>
                  </Paper>
                  <CustomNoResponseModal
                    isModalVisible={isQuestionModalVisible}
                    onPress={hideNoQuestionModal}
                    buttonText="OK"
                    modalTitle="Oops!"
                    text={apiErrorMessage}
                  />
                </section>
              )}
            </Grid>
            {/* <Grid
              item
              lg={3}
              xs={12}
              className="pb-3"
              sx={{ textAlign: 'center' }}
            >
              <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </main>
      <Footer />
    </Box>
  );
};

export default QuizResult;
